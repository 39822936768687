<template>
  <div class="app-container user-container">
    <el-form inline :model="listQuery" label-width="120px">
      <el-form-item label="联系人/申请人：">
        <el-input v-model="listQuery.conn_user" placeholder="输入联系人/电话/邮箱"></el-input>
      </el-form-item>
      <el-form-item label="参考号：">
        <el-input v-model="listQuery.order_no" placeholder="输入参考号"></el-input>
      </el-form-item>
      <el-form-item label="所属使馆：" v-if="isAdmin">
        <el-select v-model="listQuery.dipm_id" placeholder="请选择" @change="fetchData()" clearable>
          <el-option value='' label="未选择"></el-option>
          <el-option v-for="(item, index) in dipmList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="打印状态：">
        <el-select v-model="listQuery.print_status" placeholder="请选择" @change="fetchData()">
          <el-option v-for="(item, index) in printStatus" :key="index" :value="item.value" :label="item.text"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="订单状态：">
        <el-select v-model="listQuery.status" placeholder="请选择" @change="fetchData()">
          <el-option v-for="(item, index) in orderStatus" :key="index" :value="item.value" :label="item.text"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="快递费状态：">
        <el-select v-model="listQuery.delivery_pay_status" placeholder="请选择" @change="fetchData()">
          <el-option v-for="(item, index) in deliveryPayStatus" :key="index" :value="item.value" :label="item.text"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="使馆费状态：">
        <el-select v-model="listQuery.dipm_pay_status" placeholder="请选择" @change="fetchData()">
          <el-option v-for="(item, index) in dipmPayStatus" :key="index" :value="item.value" :label="item.text"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="物流状态：">
        <el-select v-model="listQuery.logistics_status" placeholder="请选择" @change="fetchData()">
          <el-option v-for="(item, index) in logisticsStatus" :key="index" :value="item.value" :label="item.text"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="推送第三方：">
        <el-select v-model="listQuery.third_party" placeholder="请选择" @change="fetchData()">
          <el-option value="" label="未选择"></el-option>
          <el-option v-for="(item, index) in [{value: 'postnord', text: 'PostNord'},{value: 'budbee', text: 'Budbee'}]" :key="index" :value="item.value" :label="item.text"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="物流类型：">
        <el-select v-model="listQuery.delivery_type" placeholder="请选择" @change="fetchData()">
          <el-option value="" label="未选择"></el-option>
          <el-option v-for="(item, index) in [{value: '10', text: '往返'}, {value: '20', text: '单程派送'}, {value: '30', text: '单程补件'}]" :key="index" :value="item.value" :label="item.text"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间：">
        <el-date-picker
          v-model="listQuery.created_arr"
          type="daterange"
          align="right"
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="fetchData(1)"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <input
          type="file"
          @click="$refs.fileRef.value = null"
          @change="doImport"
          style="display: none"
          ref="fileRef"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <el-button @click="fetchData(1)" type="primary">搜索</el-button>
        <el-button @click="onReset" type="default">重置</el-button>
        <template v-if="true">
          <el-divider direction="vertical"></el-divider>
          <el-button type="success" @click="doAdd({})" icon="el-icon-plus">申请单</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="default" @click="beforeImport" icon="el-icon-upload">批量导入</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button type="default" @click="doExport" icon="el-icon-download" :loading="downloading">批量导出</el-button>
        </template>
      </el-form-item>
    </el-form>

    <!-- 批量操作区域 -->
    <div class="mbm">
      <el-button type="default" :disabled="!multipleSelection.length" @click="showBatch()">批量更改状态</el-button>
      <el-button type="success" :disabled="!multipleSelection.length || isAdmin" @click="doBatchReview(2)">审批通过</el-button>
      <el-button type="danger" :disabled="!multipleSelection.length || isAdmin" @click="doBatchReview(3)">审批不通过</el-button>
      <el-button type="primary"  @click="print" :loading="printing">打印面单</el-button>
      <el-dropdown @command="solicitation" style="margin-left: 5px;">
        <el-button type="primary" :disabled="!multipleSelection.length || !!listQuery.third_party"  :loading="tping">
          揽件<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1">PN揽件</el-dropdown-item>
         <!-- <el-dropdown-item command="3">budbee揽件</el-dropdown-item> -->
         <!--     <el-dropdown-item command="5">顺丰揽件</el-dropdown-item>  -->
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown @command="delivery" style="margin-left: 5px;">
        <el-button type="primary" :disabled="!multipleSelection.length || !!listQuery.third_party"  :loading="tping">
          派送<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="2">PN派送</el-dropdown-item>
          <el-dropdown-item command="4">budbee派送</el-dropdown-item>
         <!--   <el-dropdown-item command="6">顺丰派送</el-dropdown-item>  -->
        </el-dropdown-menu>
      </el-dropdown>    
      <el-button style="margin-left: 5px;" type="primary" :disabled="!multipleSelection.length" @click="beforeBatchPrint" :loading="printing">下载面单</el-button>
    </div>

    <!-- 表格区域  -->
    <el-table
      :data="list"
      v-loading="loading"
      border
      fit
      hightlight-current-row
      @selection-change="handleSelectionChange"
      @cell-dblclick="cellClick"
    >
      <el-table-column type="selection" width="55" label="全选" align="center" fixed="left" />
      <el-table-column label="参考号" width="120" align="center" prop="order_no">
        <template slot-scope="{ row, column }">
          <span>{{ row[column.property]}}</span>
        </template>
      </el-table-column>
      <el-table-column label="申请人" width="120" align="center" prop="req_user">
        <template slot-scope="{ row, column }">
          <el-input autofocus v-if="editMap.uuid === row.uuid && editMap.key === column.property" v-model="editMap.value" placeholder="填写联系人" @blur="handleBlur(row, column.property)" />
          <span v-else>{{ row[column.property] || row.conn_user }}</span>
        </template>
      </el-table-column>
      <el-table-column label="使馆费" width="90" align="center" prop="dipm_fee">
        <template slot-scope="{ row, column }">
          <el-input-number v-if="editMap.uuid === row.uuid && editMap.key === column.property" v-model="editMap.value" placeholder="填写使馆费" @blur="handleBlur(row, column.property)" autofocus />
          <span v-else><svg-icon icon-class="light" :class="row.dipm_pay_status ? 'c-green' : 'c-red'" class="mrs" />{{ +(row.dipm_fee || 0) }}</span>
          <template v-for="item in getOptions(row)">
            <el-popconfirm
              v-if="item.key === 'dipmDone'" 
              title="您确定要处理完成该申请单吗？"
              @confirm="handleFinish(row)"
              >
              <el-link type="success" slot="reference">处理完成</el-link>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="快递费" width="80" align="center">
        <template slot-scope="{ row }">
          <span><svg-icon icon-class="light" :class="row.delivery_pay_status ? 'c-green' : 'c-red'" class="mrs" />{{ +(row.delivery_fee || 0) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="联系电话" width="120" align="center" prop="conn_phone">
        <template slot-scope="{ row, column }">
          <el-input v-if="editMap.uuid === row.uuid && editMap.key === column.property" v-model="editMap.value" placeholder="填写联系电话" @blur="handleBlur(row, column.property)" autofocus />
          <span v-else>{{ row[column.property]}}</span>
        </template>
      </el-table-column>
      <el-table-column label="联系邮箱" width="150" align="center" prop="conn_email">
        <template slot-scope="{ row, column }">
          <el-input v-if="editMap.uuid === row.uuid && editMap.key === column.property" v-model="editMap.value" placeholder="填写联系邮箱" @blur="handleBlur(row, column.property)" autofocus />
          <span v-else>{{ row[column.property]}}</span>
        </template>
      </el-table-column>
      <el-table-column label="城市" width="100" align="center" prop="city" />
      <el-table-column label="邮编" width="80" align="center" prop="postal_code" />
      <el-table-column label="联系地址" min-width="150" prop="to_address" />
      <el-table-column label="物流类型" width="80" align="center">
        <template slot-scope="{ row }">
          <span>{{row.delivery_type == 10 ? '往返' : (row.delivery_type == 20 ? '单程派送' : '单程补件')}}</span>
        </template>
      </el-table-column>
     <!-- <el-table-column label="申请附件" width="100" align="center">
        <template slot-scope="{ row }">
          <el-link v-if="row.files && row.files.length" type="primary" @click="preview(row)">
            {{ row.files.length }}份
          </el-link>
          <span v-else>--</span>
        </template>
      </el-table-column>  -->
      <el-table-column label="订单状态" width="120" align="center">
        <template slot-scope="{ row }">
          <order-status :order="row" />
        </template>
      </el-table-column>
      <el-table-column label="打印状态" width="80" align="center">
        <template slot-scope="{ row }">
          <svg-icon v-if="row.print_status" icon-class="dayin" class="c-green f-18" />
          <svg-icon v-else icon-class="dayinji" class="c-gray f-18" />
        </template>
      </el-table-column>
      <el-table-column label="快递信息" width="130" align="center">
          <template slot-scope="{ row }">
            <span>{{ getExpressInfo(row) }}</span>
          </template>
      </el-table-column>
      <!-- <template v-if="isAdmin"> -->
        <el-table-column label="揽件信息" width="80" align="center">
          <template slot-scope="{ row }">
            <span>{{ getPostnordByType(row, 1) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="派送信息" width="130" align="center">
          <template slot-scope="{ row }">
            <span>{{ getPostnordByType(row, 2) }}</span>
            <el-link type="danger" class="mls" v-if="getPostnordByType(row, 2) !== '--'" @click="removePnOrder(row, 2)">重推</el-link>
          </template>
        </el-table-column>
      <!-- </template> -->
      <el-table-column label="提交时间" width="100" align="center" prop="created_time" />
      <el-table-column label="订单号" width="180" align="center" prop="uuid" v-if="isAdmin" />
      <el-table-column label="操作" width="180" align="center" fixed="right">
        <template slot-scope="{ row }">
          <template v-for="item in getOptions(row)">
            <el-link v-if="item.key === 'edit'" @click="doAdd(row)" type="primary">编辑</el-link>
            <el-link v-if="false && item.key === 'print'" type="primary" target="_blank" :href="`/rest/post/nord/pdf/export?order_id=${row.uuid}&token=${token}`">下载面单</el-link>
            <el-link v-if="item.key === 'sendEmail'" @click="showSend(row)" type="primary">发邮件</el-link>
            <el-link v-else-if="item.key === 'view'" @click="doAdd(row, true)" type="success">查看</el-link>
            <el-link v-else-if="item.key === 'change'" type="success" @click="showBatch([row.uuid], { status: row.status, delivery_pay_status: row.delivery_pay_status, dipm_pay_status: row.dipm_pay_status, print_status: row.print_status })">状态</el-link>
            <el-popconfirm
              v-else-if="item.key === 'dipmDone'"
              title="您确定要处理完成该申请单吗？"
              @confirm="handleFinish(row)"
              >
              <el-link type="success" slot="reference">处理完成</el-link>
            </el-popconfirm>
            <el-popconfirm
              v-else-if="item.key === 'pass'"
              title="您确定要通过该申请单吗？"
              @confirm="doReview(row, 2)"
              >
              <el-link type="success" slot="reference">通过</el-link>
            </el-popconfirm>
            <el-link type="danger" slot="reference" @click="showReject(row)" v-else-if="item.key === 'fail'">拒绝</el-link>
            <el-popconfirm
              v-else-if="item.key === 'cancel'"
              title="您确定要取消该申请单吗？"
              @confirm="updateStatus(row, {status: 99})"
              >
              <el-link type="danger" slot="reference">取消</el-link>
            </el-popconfirm>
            <el-popconfirm
              v-else-if="item.key === 'restart'"
              title="您确定要重启该申请单吗？"
              @confirm="updateStatus(row, {status: 10, review_status: 1})"
              >
              <el-link type="warning" slot="reference">重启</el-link>
            </el-popconfirm>
            <el-popconfirm
              v-else-if="item.key === 'del'"
              title="您确定要删除该申请单吗？"
              @confirm="del(row)"
              >
              <el-link type="danger" slot="reference">删除</el-link>
            </el-popconfirm>
            <el-divider v-if="!item.isLast" direction="vertical"></el-divider>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="listQuery.page"
      :page-size="listQuery.page_size"
      :total="total"
      style="padding: 32px 0;"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      @current-change="fetchData"
      @size-change="sizeChange"
    />

    <!-- 申请单提交表单 -->
    <el-dialog
      :title="postData.uuid > 0 ? '编辑申请单' : '提交申请单'"
      :visible.sync="dialogVisible"
      destroy-on-close
      :key="dialogIndex"
      @close="dialogIndex++"
    >
      <el-form label-width="140px" :model="postData" :rules="rules" ref="postForm" :disabled="dialogDisabled">
        <el-form-item label="所属使馆：" prop="dipm_id" v-if="isAdmin">
          <el-select v-model="postData.dipm_id" placeholder="请选择所属使馆" :disabled="postData.uuid > 0" style="width:60%" @change="dipmChange">
            <el-option v-for="(item, index) in dipmList" :key="item.id" :value="item.id" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参考号：" prop="order_no">
          <el-input v-model="postData.order_no" placeholder="由系统自动生成" style="width: 60%" disabled></el-input>
        </el-form-item>
        <el-form-item label="物流类型：" prop="delivery_type">
          <el-radio-group v-model="postData.delivery_type" placeholder="请选择">
            <el-radio v-for="(item, index) in [{value: 10, text: '往返'}, {value: 20, text: '单程派送'}, {value: 30, text: '单程补件'}]" :key="index" :label="item.value">{{item.text}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="申请人(中文)：" prop="req_user">
          <el-input v-model="postData.req_user" placeholder="申请人（中文名）"  style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="联系人：" prop="conn_user">
          <el-input v-model="postData.conn_user" placeholder="联系人"  style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="conn_phone">
          <el-input v-model="postData.conn_phone" placeholder="联系电话" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱：" prop="conn_email" v-if="dipmType != 1">
          <el-input v-model="postData.conn_email" placeholder="联系邮箱" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="联系地址：" prop="to_address">
          <el-autocomplete
            style="width: 80%"
            v-model="postData.to_address"
            :fetch-suggestions="googleMapSearchAsync"
            placeholder="填写联系地址"
            @select="handleAddressSelect"
          >
            <template slot-scope="{ item }">
              <div>
                <span class="pac-icon"></span>
                {{ item.description }}
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="LGH：" prop="lgh">
          <el-input v-model="postData.lgh" placeholder="LGH（选填）" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码：" prop="postal_code">
          <el-input v-model="postData.postal_code" placeholder="邮政编码" style="width:60%"></el-input>
        </el-form-item>
        <el-form-item label="所在城市：" prop="city">
          <el-input v-model="postData.city" placeholder="所在城市" style="width:60%"></el-input>
        </el-form-item>
        <el-form-item label="快递费：" prop="delivery_fee">
          <el-radio-group v-model="postData.deliveryType" @change="updateDeliveryFee">
            <el-radio v-for="item in deliveryType" :label="item.label" :key="item.label">{{ item.text }}</el-radio>
          </el-radio-group>
          <el-input-number :disabled="postData.deliveryType != 'custom'" v-model="postData.delivery_fee" placeholder="填写快递费" style="margin-left:20px" size="medium" :min="0" />
        </el-form-item>
        <el-form-item label="使馆项目：" prop="orderCosts" style="margin-bottom: 0">
          <div v-for="(item, index) in postData.orderCosts" :key="index" class="dipm-item">
            <el-select v-model="item.cost_id" placeholder="选择使馆费类别" @change="calcDipmFee(index, $event)">
              <el-option v-for="type in typeList" :key="type.id" :value="type.id" :label="`${type.type_name}（${+type.price} ${feeType}）`"></el-option>
            </el-select>
            <el-checkbox @change="calcDipmUrgent(index, $event)" v-model="item.is_urgent" style="margin: 0 20px" :disabled="!item.cost_id" :true-label="1" :false-label="0">加急</el-checkbox>
            <el-link type="danger" v-if="item.cost_id" @click="removeDipm(index)">取消</el-link>
          </div>
          <div v-if="false" class="dipm-fee">合计使馆费：<span class="dipm-fee-num">{{dipm_fee || 0}}{{feeTypeName}}</span></div>
        </el-form-item>
        <el-form-item label="合计使馆费：" prop="dipm_fee">
          <el-input-number v-if="dipm_fee > 0" :value="dipm_fee" disabled placeholder="选择使馆费类别" :min="0"></el-input-number>
          <el-input-number v-else v-model="postData.dipm_fee" placeholder="填写使馆费" :min="0"></el-input-number>
          <span class="mls">{{feeTypeName}}</span>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" :rows="2" placeholder="请填写备注事项" v-model="postData.remark" style="width: 80%"></el-input>
        </el-form-item>
        <template>
          <el-form-item label="重量：" prop="weight">
            <el-input v-model="postData.weight" placeholder="重量" style="width:180px"></el-input>
            <span class="mlm">KG</span>
          </el-form-item>
          <el-form-item label="申请附件：" prop="files">
            <el-upload
              action="/rest/order/file/upload"
              :file-list="postData.files"
              accept=".docx,.doc,.pdf,.xls,.xlsx,.jpg,.png,.zip,.rar"
              :on-change="handleFileChange"
              :on-preview="handleFilePreview"
              :headers="{
                Authentication: `Basic ${token}`
              }"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip" v-if="false">只能上传jpg/png文件，且不超过500kb</div>
            </el-upload>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{dialogDisabled ? '关 闭' : '取 消'}}</el-button>
        <el-button type="primary" @click="onSubmit(false)" :loading="uploading" v-if="!dialogDisabled">确 定</el-button>
        <span v-if="!postData.uuid">
          <el-divider direction="vertical"></el-divider>
          <el-button type="primary" @click="onSubmit(true)" :loading="uploading">保存 & 添加下一个</el-button>
        </span>
      </div>
    </el-dialog>

    <!-- 申请单提交表单 -->
    <el-dialog
      title="批量更新申请单状态"
      :visible.sync="batchVisible"
      destroy-on-close
      :key="batchIndex"
      @close="batchIndex++"
      width="40%"
    >
      <el-form ref="batchRef" label-width="100px">
        <el-form-item label="订单状态：">
          <el-select v-model="batchData.status" placeholder="请选择">
            <el-option v-for="(item, index) in orderStatus.filter(v => v.value !== '')" :key="index" :value="item.value" :label="item.text"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核状态：" v-if="false">
          <el-select v-model="batchData.review_status" placeholder="请选择">
            <el-option v-for="(item, index) in orderReviewStatus.filter(v => v.value !== '')" :key="index" :value="item.value" :label="item.text"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递费状态：">
          <el-select v-model="batchData.delivery_pay_status" placeholder="请选择">
            <el-option v-for="(item, index) in deliveryPayStatus.filter(v => v.value !== '')" :key="index" :value="item.value" :label="item.text"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使馆费状态：">
          <el-select v-model="batchData.dipm_pay_status" placeholder="请选择">
            <el-option v-for="(item, index) in dipmPayStatus.filter(v => v.value !== '')" :key="index" :value="item.value" :label="item.text"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打印状态：">
          <el-select v-model="batchData.print_status" placeholder="请选择">
            <el-option v-for="(item, index) in printStatus.filter(v => v.value !== '')" :key="index" :value="item.value" :label="item.text"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batchVisible = false">取 消</el-button>
        <el-button type="primary" @click="onBatch" :loading="uploading" :disabled="batchDisabled">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 发送邮件 -->
    <el-dialog
      :visible.sync="emailVisible"
      title="发送邮件"
      destroy-on-close
      :key="emailDialogIndex"
      @close="emailDialogIndex++"
    >
      <el-form label-width="100px" style="width:60%" ref="emailRef" :rules="emailRules" :model="emailData">
        <el-form-item label="申请人：">
          <span>{{ emailData.conn_user }}</span>
        </el-form-item>
        <el-form-item label="参考号：">
          <span>{{ emailData.order_no }}</span>
        </el-form-item>
        <el-form-item label="快递费：">
          <span>{{ +(emailData.delivery_fee || 0) }}({{ emailData.delivery_pay_status_name }})</span>
        </el-form-item>
        <el-form-item label="使馆费：">
          <span>{{ +(emailData.dipm_fee || 0) }}({{ emailData.dipm_pay_status_name }})</span>
        </el-form-item>
        <el-form-item label="邮件地址：" prop="conn_email">
          <el-input placeholder="邮件地址" v-model="emailData.conn_email"></el-input>
        </el-form-item>
        <el-form-item label="通知内容：" prop="feeRange">
          <el-checkbox-group v-model="emailData.feeRange">
            <el-checkbox label="delivery_fee">快递费</el-checkbox>
            <el-checkbox label="dipm_fee">使馆费</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="emailVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSend" :loading="sending">发 送</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="`${tpData.title}（完善${tpData.type == 2 || tpData.type == 4 ? '发' : '收'}件人信息）`"
      :visible.sync="tpVisible"
      destroy-on-close
      :key="tpIndex"
      @close="tpIndex++"
    >
      <el-form label-width="200px" ref="tpForm" :model="tpData" :rules="tpRules" style="width:60%">
        <el-form-item :label="`选择${tpData.type == 2 || tpData.type == 4 ? '发' : '收'}件人：`" required>
          <el-select v-model="senderIndex" placeholder="请选择" @change="senderChange" style="width:100%">
            <el-option v-for="(item, index) in senders" :key="index" :value="index" :label="item.conn_user"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`${tpData.type == 2 || tpData.type == 4 ? '发' : '收'}件人国家：`" prop="country">
          <el-select v-model="tpData.country" placeholder="选择国家">
            <el-option v-for="item in country" :key="item.key" :value="item.key" :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="`${tpData.type == 2 || tpData.type == 4 ? '发' : '收'}件人城市：`" prop="area">
          <el-input v-model="tpData.area" placeholder="填写城市"></el-input>
        </el-form-item>
        <el-form-item label="邮政编码：" prop="postal_code">
          <el-input v-model="tpData.postal_code" placeholder="邮政编码"></el-input>
        </el-form-item>
        <el-form-item :label="`${tpData.type == 2 || tpData.type == 4 ? '发' : '收'}件人地址：`" prop="address">
          <el-input v-model="tpData.address" placeholder="填写详情地址"></el-input>
        </el-form-item>
        <el-form-item :label="`${tpData.type == 2 || tpData.type == 4 ? '发' : '收'}件人：`" prop="conn_user">
          <el-input v-model="tpData.conn_user" placeholder="填写姓名"></el-input>
        </el-form-item>
        <el-form-item label="所在公司：" prop="company_name">
          <el-input v-model="tpData.company_name" placeholder="所在公司"></el-input>
        </el-form-item>
        <el-form-item label="客户编号：" prop="customer_number">
          <el-input v-model="tpData.customer_number" placeholder="客户编号"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱：" prop="email">
          <el-input v-model="tpData.email" placeholder="联系邮箱"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="conn_phone" help="用于收货人短信通知" v-if="tpData.type == 1">
          <el-input v-model="tpData.conn_phone" placeholder="联系电话"></el-input>
        </el-form-item>
        <el-form-item label="Service Code：" prop="basicServiceCode">
          <el-select placeholder="PostNord Service Code" v-model="tpData.basicServiceCode" style="width:100%" @change="onServiceCodeChange">
            <el-option v-for="(item, index) in serviceCode" :key="index" :value="item.basicServiceCode" :label="`${item.name}（${item.basicServiceCode}）`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Additional Service Code：" prop="additionalServiceCode">
          <el-select placeholder="PostNord Additional Service Code" v-model="tpData.additionalServiceCode" style="width:100%">
            <el-option v-for="(item, index) in (tpData.basicServiceCode ? serviceCode.find(v => v.basicServiceCode == tpData.basicServiceCode).additionalServiceCode || [] : [])" :key="index" :value="item.code" :label="`${item.name}（${item.code}）`"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="tpVisible = false">取 消</el-button>
        <el-button type="primary" @click="onSync" :loading="tping">{{tpData.type == 1 || tpData.type == 3 ? '揽件' : '派送'}}</el-button>
      </div>
    </el-dialog>

    <!-- 图片预览区域 -->
    <el-image-viewer
      v-if="previewImg.visible"
      :url-list="previewImg.files"
      :on-close="closeViewer"
    />

    <!-- 选择面单类型 -->
    <c-dialog title="选择面单类型" width="40%" :on-submit="batchPrint" :visible.sync="pnVisible">
      <el-form>
        <el-form-item label="面单类型：">
          <el-radio-group v-model="pnData.type">
            <el-radio :label="1">揽件</el-radio>
            <el-radio :label="2">派送</el-radio>
            <el-radio :label="3">当日派送</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </c-dialog>
  </div>
</template>

<script type="text/javascript">
import type from '@/api/type'
import api from '@/api/order'
import common from '@/api/common'
import dipm from '@/api/dipm'
import { deliveryPayStatus, dipmPayStatus, orderStatus, orderLogStatus, orderReviewStatus, printStatus, logisticsStatus } from '@/utils/constants'
import { download, tplRender } from '@/utils/index'
import { DIPM_FEE_TPL, DELIEVERY_FEE_TPL } from '@/utils/email'
import { mapGetters, mapState } from 'vuex'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

const listQuery = {
  page: 1,
  page_size: +localStorage.getItem('page_size') || 20,
  status: 999,
  delivery_pay_status: '',
  dipm_pay_status: '',
  logistics_status: '',
  dipm_id: '',
  third_party: ''
}
const tpData = {
  title: '',
  country: 'SE',
  area: 'Arlandastad',
  postal_code: 19560,
  email: 'info@stoepx.com',
  company_name: 'Murop AB',
  customer_number: '20803901',
  address: 'Pionjärvägen 23',
  conn_user: 'Murop AB',
  conn_phone: '0732056789',
}
const senders = [
  // {
  //   country: 'SE',
  //   area: 'Arlandastad',
  //   postal_code: 19560,
  //   email: 'info@stoepx.com',
  //   company_name: 'Murop AB',
  //   customer_number: '20803901',
  //   address: 'Pionjärvägen 23',
  //   conn_user: 'Murop AB',
  //   conn_phone: '0732056789',
  // },
  // {
  //   country: 'SE',
  //   area: 'Göteborg',
  //   postal_code: 41136,
  //   email: 'info@stoepx.com',
  //   company_name: 'Murop AB',
  //   customer_number: '20803901',
  //   address: 'Lorensbergsgatan 18 LGH 1824',
  //   conn_user: 'George',
  //   conn_phone: '0738557888',
  // },
  {
    country: 'SE',
    area: 'Stockholm',
    postal_code: 11525,
    email: 'info@stoepx.com',
    company_name: 'Murop AB',
    customer_number: '20803901',
    address: 'Lidovägen 8',
    conn_user: "KINAS AMBASSAD",
    conn_phone: '0724448073',
  },
  // {
  //   country: 'SE',
  //   area: 'Arlandastad',
  //   postal_code: 19560,
  //   email: 'info@stoepx.com',
  //   company_name: 'Murop AB',
  //   customer_number: '20803901',
  //   address: 'Pionjärvägen 23',
  //   conn_user: 'KINAS AMBASSAD C/O Murop AB',
  //   conn_phone: '0732056789',
  // },
  {
    country: 'SE',
    area: 'Göteborg',
    postal_code: 40426,
    email: 'info@stoepx.com',
    company_name: 'Murop AB',
    customer_number: '20803901',
    address: 'Box 11294',
    conn_user: 'Consulate-General of the P.R.China',
    conn_phone: '0732056789',
  },
  // {
  //   country: 'SE',
  //   area: 'Vällingby',
  //   postal_code: 16250,
  //   email: 'ruidianhuazhu888@gmail.com',
  //   company_name: 'Murop AB',
  //   customer_number: '20803901',
  //   address: 'Siktgatan 7',
  //   conn_user: 'OVERSEAS CHINESE SERVICE CENTER',
  //   conn_phone: '0732056789',
  // },
]
/*
const tpData = {
  country: 'SE',
  area: 'Arlandastad',
  postal_code: 19560,
  email: 'info@stoepx.com',
  company_name: 'Murop AB',
  customer_number: '20803901',
  address: 'Pionjärvägen 23',
  conn_user: 'Sto Express Sweden',
}
*/
export default {
  components: { ElImageViewer },
  data() {
    return {
      editMap: {},
      country: [
        { name: 'Sweden', key: 'SE' },
        { name: 'Finland', key: 'FL' },
        { name: 'Denmark', key: 'DK' },
        { name: 'Norway', key: 'NO' },
      ],
      serviceCode: [
        { basicServiceCode: 11, name: 'Posten Varubrev Ekonomi', additionalServiceCode: [{code: 'F3', name: 'Leverans vid Dörr'}] },
        { basicServiceCode: 30, additionalServiceCode: [{code: 79, name: 'Addressering till boxadress'}], name: 'MyPack Home Small' },
      ],
      previewImg: {},
      senders,
      listQuery: { ...listQuery },
      loading: false,
      list: [],
      total: 0,
      multipleSelection: [],

      pnData: {},
      pnVisible: false,
      printing: false,
      postData: {
        orderCosts: [{}],
        files: [],
      },
      dialogVisible: false,
      dialogDisabled: false,
      dialogIndex: 0,
      uploading: false,
      downloading: false,
      rules: {
        //order_no: [{ required: true, message: '受理单号必填' }],
        conn_user: [{ required: true, message: '联系人必填' }],
        req_user: [
          { required: true, message: '申请人必填' },
          { pattern: /^[\u4E00-\u9FA5]+(·[\u4E00-\u9FA5]+)*$/, message: '申请人只能填写中文姓名' }
        ],
        conn_phone: [{ required: true, message: '联系电话必填' }],
        conn_email: [{ required: true, type: 'email', message: '联系邮箱必填' }],
        to_address: [{ required: true, message: '联系地址必填' }],
        dipm_id: [{ required: true, message: '所属使馆必选' }],
        delivery_type: [{ required: true, message: '请选择' }],
        //city: [{ required: true, message: '请填写' }],
        //postal_code: [{ required: true, message: '请填写' }],
        //orderCosts: [{ required: true, message: '使馆项目必选' }],
      },
      typeList: [],
      dipmList: [],

      printStatus,
      orderStatus,
      dipmPayStatus,
      logisticsStatus,
      deliveryPayStatus,
      orderReviewStatus,
      orderLogStatus,
      orderStatusTagType: { 99: 'danger', 70: 'success' },
      /*
      deliveryType: [
        { label: 1, value: 199, text: '单向' },
        { label: 2, value: 299, text: '双向' },
        { label: 3, value: 0, text: '其它' },
      ],
      */

      // 批量操作
      batchIndex: 100,
      batchVisible: false,
      batchData: {},

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },

      // 邮件相关
      emailData: {
        feeRange: []
      },
      emailVisible: false,
      emailDialogIndex: 200,
      emailRules: {
        conn_email: [
          { required: true, type: 'email', message: '请输入正确的邮箱地址' }
        ],
        feeRange: [
          { required: true, message: '请选择通知范围' }
        ]
      },
      sending: false,

      // 第三方相关
      tpVisible: false,
      tpIndex: 400,
      tping: false,
      senderIndex: 0,
      tpData: { ...senders[0], type: 2, title:'' },
      tpRules: {
        country: [ { required: true, message: '请选择' } ],
        area: [ { required: true, message: '请填写' } ],
        postal_code: [ { required: true, message: '请填写' } ],
        address: [ { required: true, message: '请填写' } ],
        conn_user: [ { required: true, message: '请填写' } ],
        company_name: [ { required: true, message: '请填写' } ],
        customer_number: [ { required: true, message: '请填写' } ],
        conn_phone: [ { required: true, message: '请填写' } ],
        email: [ { required: true, message: '请填写' } ],
        basicServiceCode: [ { required: true, message: '请选择' } ],
        //additionalServiceCode: [ { required: true, message: '请选择' } ],
      },
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'token']),
    ...mapState(['user']),
    dipmType() {
      if (this.isAdmin) {
        return this.postData.dipm_id && this.dipmList.find(v => v.id === this.postData.dipm_id).type || ''
      }
      return this.user.diplomaticDTO.type || ''
    },
    feeType() {
      if (this.isAdmin) {
        return this.postData.dipm_id && this.dipmList.find(v => v.id === this.postData.dipm_id).pay_type || ''
      }
      return this.user.diplomaticDTO.pay_type || ''
    },
    isSpecailFlow() {
      if (this.isAdmin) return false
      return this.user.diplomaticDTO?.is_special_flow
    },
    feeTypeName() {
      if (this.isAdmin) {
        return this.postData.dipm_id && this.dipmList.find(v => v.id === this.postData.dipm_id).pay_type_name || ''
      }
      return this.user.diplomaticDTO.pay_type_name || ''
    },
    dipm_fee() {
      let total = 0
      this.postData.orderCosts.map(v => {
        if (v.cost_id) {
          const type = this.typeList.find(item => item.id === v.cost_id)
          if (type) {
            total += Number(type.price) + (v.is_urgent ? Number(type.urgent_fee || '') : 0)
          }
        }
      })
      return total
    },
    batchDisabled() {
      const { status, dipm_pay_status, delivery_pay_status } = this.batchData
      return status === undefined && dipm_pay_status === undefined && delivery_pay_status === undefined
    },
    deliveryType() {
      let list = []
      if (this.isAdmin) {
        if (this.postData.dipm_id) {
          list = this.dipmList.find(v => v.id === this.postData.dipm_id).delivery_type || []
        }
      }
      else {
        list = this.user.diplomaticDTO.delivery_type || []
      }
      return [
        ...list.map((v, index) => ({ label: index + 1, value: v.fee, text: v.name })),
        //{ label: list.length + 1, value: 0, text: '其他' }
        { label: 'custom', value: 0, text: '其他' }
      ]
    },
  },
  created() {
    this.fetchData()
    if (this.isAdmin) {
      this.getDipmList()
    }
    else {
      this.getType()
    }
    console.log(this.user)
  },
  mounted() {
    this.autocompleteService = new google.maps.places.AutocompleteService()
    this.placesService = new google.maps.places.PlacesService(document.createElement('div'))
  },
  methods: {
    onServiceCodeChange(val) {
      this.tpData.additionalServiceCode = ''
      //this.tpData.additionalServiceCode = this.serviceCode.find(v => v.basicServiceCode == val).additionalServiceCode[0]?.code || ''
    },
    getDipmList() {
      dipm.getList({ page_size: 999 })
      .then(res => {
        this.dipmList = res.list || []
      })
    },
    beforeImport() {
      if (this.isAdmin && !this.listQuery.dipm_id) {
        this.$message.error('请先选择所属使馆！')
        return false
      }
      this.$refs.fileRef.click()
    },
    doImport(event) {
      const files = event.target.files
      if (!files.length) {
        this.$message.error('请选择文件')
        return false
      }
      const cb = this.$loading({ text: '导入中...' })
      const fd = new FormData()
      fd.append('file', files[0])
      // 对应的使馆id
      fd.append('dipm_id', this.listQuery.dipm_id || 0)

      api.import(fd)
      .then(() => {
        this.$message.success('导入成功')
        this.fetchData()
        cb.close()
      })
      .catch(() => {
        cb.close()
      })
    },
    getType(dipm_id = '') {
      type.getList({ page_size: 999, dipm_id })
      .then(res => {
        this.typeList = res.list || []
      })
    },
    sizeChange(page_size) {
      this.listQuery.page_size = page_size
      localStorage.setItem('page_size', page_size)
      this.fetchData()
    },
    fetchData(page = 1) {
      this.listQuery.page = page
      this.loading = true
      api.getList({
        ...this.listQuery,
      })
      .then(res => {
        this.total = res.total
        this.list = res.list || []
        this.loading = false
      })
    },
    onReset() {
      this.listQuery = { ...listQuery }
      this.fetchData()
    },
    doAdd(row, disabled = false) {
      this.dialogDisabled = disabled
      const addressInfo = (row.to_address || '').split(/,?\s*lgh\s*/i)
      this.postData = {
        postal_code: '',
        city: '',
        delivery_type: 10,
        ...row,
        orderCosts: row.orderCosts ? [ ...row.orderCosts, {} ] : [ {} ],
        files: (row.files || []).map(v => ({ id: v.id, name: v.file_name, url: `/rest/order/file/${v.id}/download` })),
        to_address: addressInfo[0],
        lgh: addressInfo[1] || '',
      }

      // 解决管理员编辑回填派送费类别的问题
      this.$nextTick(() => {
        const item = this.deliveryType.find(v => v.value == row.delivery_fee)
        const deliveryType = (item && item.label) || this.deliveryType.length - 1
        this.postData = {
          ...this.postData,
          deliveryType,
          delivery_fee: row.delivery_fee > 0 ? row.delivery_fee : ((this.deliveryType.find(v => v.label === deliveryType) || {}).value || 0)
        }
        this.dialogVisible = true
      })
      if (row.dipm_id) {
        this.getType(row.dipm_id)
      }
    },
    onSubmit(next = false) {
      this.$refs.postForm.validate(valid => {
        if (!valid) return false
        this.uploading = true
        const body = {
          ...this.postData,
          orderCosts: this.postData.orderCosts.filter(v => v.cost_id > 0),
          file_ids: this.postData.files.map(v => v.id),
          to_address: this.postData.to_address + (this.postData.lgh ? `, LGH ${this.postData.lgh}` : ''),
        }

        if (this.postData.uuid > 0) {
          delete body.dipm_fee_status
          delete body.delivery_fee_status
          api.update(this.postData.uuid, body)
          .then(() => {
            this.$message.success('修改成功')
            this.fetchData(this.listQuery.page)
            this.uploading = false
            this.dialogVisible = false
          })
          .catch(() => {
            this.uploading = false
          })
          return
        }
        api.add(body)
        .then(uuid => {
          this.$message.success('添加成功')
          this.fetchData()
          this.uploading = false
          if (!next) {
            this.dialogVisible = false
          }
          else {
            this.showAdd({})
          }
        })
        .catch(() => {
          this.uploading = false
        })
      })
    },
    removeDipm(index) {
      this.postData.orderCosts = this.postData.orderCosts.filter((v, i) => i !== index)
    },
    calcDipmFee(index, $event) {
      const dipmCount = this.postData.orderCosts.length
      if (index >= dipmCount - 1) {
        this.postData.orderCosts = [ ...this.postData.orderCosts, {} ]
      }
    },
    calcDipmUrgent(index, $event) {
      this.postData.orderCosts = [ ...this.postData.orderCosts ]
    },
    handleFinish(row) {
      api.handleFinish(row.uuid)
      .then(order => {
        this.$message.success('操作成功')
        this.fetchData(this.listQuery.page)

        // 判断是否需要发送邮件（使馆费支付邮件）
        if (order.status == 50) {
          common.sendEmail({
            title: '申通快递 STO Express',
            email: row.conn_email,
            content: tplRender(DIPM_FEE_TPL, {
              conn_user: row.conn_user || '',
              url: `https://sweden.stoepx.com/order/${row.uuid}`,
              dipm_fee: +row.dipm_fee
            })
          })
        }
      })
    },
    updateStatus(row, status) {
      api.update(row.uuid, status)
      .then(() => {
        this.$message.success('操作成功')
        this.fetchData(this.listQuery.page)
      })
    },
    doExport() {
      this.downloading = true
      api.export(this.listQuery)
      .then(res => {
        this.downloading = false
        download(res, '申请单导出.xls')
      })
      .catch(() => {
        this.downloading = false
      })
    },
    del(row) {
      api.del(row.uuid)
      .then(() => {
        this.$message.success('删除成功')
        this.list = this.list.filter(v => v.uuid !== row.uuid)
        this.total -= 1
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    showBatch(uuid, initialStatus = {}) {
      this.batchUuid = uuid
      this.batchData = { ...initialStatus }
      this.batchVisible = true
    },
    print() {
      let body = [];
      if (this.multipleSelection.length === 0) {
         body = this.list.filter(v => !v.print_status).map(v => {
          return {
            order_id: v.uuid,
            order_no: v.order_no,
            dipm_abbr: v.dipm_abbr,
            req_user: v.req_user,
            conn_user: v.conn_user,
            conn_phone: v.conn_phone,
            to_address: v.to_address,
            postal_code: v.postal_code,
            city: v.city,
            remark: v.remark,
          }
        })
        if (!body.length) {
          this.$message.error('本页已无可打印单据，请重新选择或翻页')
          return;
        }
      }else{
         body = this.multipleSelection.filter(v => !v.print_status).map(v => {
          return {
            order_id: v.uuid,
            order_no: v.order_no,
            dipm_abbr: v.dipm_abbr,
            req_user: v.req_user,
            conn_user: v.conn_user,
            conn_phone: v.conn_phone,
            to_address: v.to_address,
            postal_code: v.postal_code,
            city: v.city,
            remark: v.remark,
          }
        })
        if (!body.length) {
          this.$message.error('所选单据已打印，请重新选择')
          return;
        }
      }
      this.printing = true
      this.$store.dispatch('formatOrders', body)
      .then(() => {
        this.printing = false
        window.print()
        // 批量更新状态
        this.batchUpdate({ print_status: 1 }, body.map(v => v.order_id))
      })
      .catch(() => {
        this.$message.error('打印失败')
        this.printing = false
      })
    },
    batchUpdate(data, ids) {
      const cb = this.$loading()
      const body = {
        order_ids: ids || this.multipleSelection.map(v => v.uuid),
        ...data
      }
      api.batch(body)
      .then(() => {
        this.$message.success('批量更新成功')
        this.fetchData(this.listQuery.page)
        this.batchVisible = false
        cb.close()
      })
      .catch(() => {
        cb.close()
      })

      // 这里只推送快递费支付邮件
      if (data.status == 20) {
        for (let id of body.order_ids) {
          const order = this.list.find(v => v.uuid === id)
          if (!order) continue;

          common.sendEmail({
            title: '申通快递 STO Express',
            email: order.conn_email,
            content: tplRender(DELIEVERY_FEE_TPL, {
              conn_user: order.conn_user || '',
              url: `https://sweden.stoepx.com/order/${order.uuid}`,
              delivery_fee: +order.delivery_fee,
            })
          })

          this.isSpecailFlow && common.sendEmail({
            title: '申通快递 STO Express',
            email: order.conn_email,
            content: tplRender(DIPM_FEE_TPL, {
              conn_user: order.conn_user || '',
              url: `https://sweden.stoepx.com/order/${order.uuid}`,
              dipm_fee: +order.dipm_fee,
            })
          })
        }
      }
    },
    onBatch() {
      let data = { ...this.batchData }
      // TODO 兼容数据
      this.batchUpdate(data, this.batchUuid)
    },
    getOptions(row) {
      let options = []
      if (row.status > 0 && row.status < 70) {
        options.push({
          key: 'edit',
        })
      }
      else {
        options.push({ key: 'view' })
      }
      if (this.listQuery.third_party) {
        options.push({ key: 'print' })
      }
      // 取消
      if (this.isAdmin && row.status > 10 && row.status < 70) {
        options.push({ key: 'change' })
        if (this.isAdmin) {
          options.push({ key: 'sendEmail' })
        }
      }
      // 待审核 + 未审核的订单
      if (row.status === 10 && row.review_status == 1 && !this.isAdmin) {
        options.push({ key: 'pass' })
        options.push({ key: 'fail' })
      }
      // 审核通过并且未付费的订单，或者审核失败的订单
      if (row.status === 20 || (row.status === 10 && row.review_status == 3)) {
        options.push({ key: 'cancel' })
      }
      // 处理中
      if (row.status === 40) {
        options.push({ key: 'dipmDone' })
      }
      if (row.status === 99) {
        options.push({ key: 'restart' })
      }
      // 删除
      if (this.isAdmin) {
        options.push({
          key: 'del'
        })
      }
      const len = options.length
      return options.map((v, index) => ({ ...v, isLast: index === len - 1 }))
    },
    doReview(row, status, reject_reason = '') {
      api.review(row.uuid, status, { reject_reason })
      .then(() => {
        this.$message.success('操作成功')
        this.fetchData(this.listQuery.page)
      })

      // 审核通过
      if (status == 2) {
        this.reviewPassAndSendEmail(row)
      }
    },
    // 批量审核通过
    async doBatchReview(review_status) {
      const orders = this.multipleSelection.map(v => v.uuid)
      if (!orders.length) return false
      let cb = null
      try {
        if(review_status == 2){
          cb = this.$loading({ text: '处理中...' })
          await api.batchReview({ orders }, review_status)
          // 循环发送邮件
          for (let uuid of orders) {
            const order = this.list.find(v => v.uuid === uuid)
            if (!order) return;
            // 发送邮件不影响后续
            try {
              await this.reviewPassAndSendEmail(order)
            } catch (e) {
              console.log('邮件发送失败', e)
            }
          }
          cb.close()
          this.fetchData(this.listQuery.page)
          this.$message.success('批量操作成功')
        }else{
          this.$prompt('请填写拒绝原因', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputPlaceholder: '拒绝原因（选填）'
          })
          .then(({ value }) => {
            cb = this.$loading({ text: '处理中...' })
            const reject_reason = value
            api.batchReview({ orders, reject_reason}, review_status)
            .then(() => {
              this.fetchData(this.listQuery.page)
              this.$message.success('批量操作成功')
              cb.close()
            })
          })
        }
      } catch(e) {
        console.log('doBatchReview error', e)
        cb.close()
      }
    },
    reviewPassAndSendEmail(row) {
      let xhrs = []
      xhrs.push(
        common.sendEmail({
          title: '申通快递 STO Express',
          email: row.conn_email,
          content: tplRender(DELIEVERY_FEE_TPL, {
            conn_user: row.conn_user || '',
            url: `https://sweden.stoepx.com/order/${row.uuid}`,
            delivery_fee: +row.delivery_fee,
          })
        })
      )
      if (this.isSpecailFlow) {
        xhrs.push(
          common.sendEmail({
            title: '申通快递 STO Express',
            email: row.conn_email,
            content: tplRender(DIPM_FEE_TPL, {
              conn_user: row.conn_user || '',
              url: `https://sweden.stoepx.com/order/${row.uuid}`,
              dipm_fee: +row.dipm_fee,
            })
          })
        )
      }
      return Promise.all(xhrs)
    },
    updateDeliveryFee(type) {
      const item = this.deliveryType.find(v => v.label === type)
      this.postData.delivery_fee = item.label < 3 ? item.value : this.postData.delivery_fee
    },
    showSend(row) {
      this.emailData = {
        feeRange: ['delivery_fee', 'dipm_fee'],
        ...row,
        dipm_fee: +(row.dipm_fee || 0),
        delivery_fee: +(row.delivery_fee || 0),
      }
      this.emailVisible = true
    },
    onSend() {
      this.$refs.emailRef.validate(valid => {
        if (!valid) return false
        this.sending = true
        const delivery_fee = this.emailData.feeRange.indexOf('delivery_fee') !== -1
          ? (this.emailData.delivery_pay_status > 0 ? 0 : this.emailData.delivery_fee || 0)
          : 0;
        const dipm_fee = this.emailData.feeRange.indexOf('dipm_fee') !== -1
          ? (this.emailData.dipm_pay_status > 0 ? 0 : this.emailData.dipm_fee || 0)
          : 0;
        let xhr = []
        if (delivery_fee > 0) {
          xhr.push(
            common.sendEmail({
              title: '申通快递 STO Express',
              email: this.emailData.conn_email,
              content: tplRender(DELIEVERY_FEE_TPL, {
                conn_user: this.emailData.conn_user || '',
                url: `https://sweden.stoepx.com/order/${this.emailData.uuid}`,
                delivery_fee,
              })
            })
          )
        }
        if (dipm_fee > 0) {
          xhr.push(
            common.sendEmail({
              title: '申通快递 STO Express',
              email: this.emailData.conn_email,
              content: tplRender(DIPM_FEE_TPL, {
                conn_user: this.emailData.conn_user || '',
                url: `https://sweden.stoepx.com/order/${this.emailData.uuid}`,
                dipm_fee,
              })
            })
          )
        }
        if (!xhr.length) {
          this.$message.info('无可发邮件')
          this.emailVisible = false
          this.sending = false
          return;
        }
        Promise.all(xhr)
        .then(() => {
          this.$message.success('发送成功')
          this.emailVisible = false
          this.sending = false
        })
        .catch(() => {
          this.sending = false
        })
      })
    },
    dipmChange(dipm_id) {
      this.getType(dipm_id)
    },
    senderChange(row) {
      this.tpData = { ...this.tpData, ...senders[row] }
    },
    beforeSync(type = 2) {
      let title = "";
      if(type == 1){
        title = "PN揽件" 
        this.senderIndex = 0
      }else if(type == 2){
        title = "PN派送" 
        this.senderIndex = 0
      }else if(type == 3){
        title = "budbee揽件" 
        this.senderIndex = 0
      }else if(type == 4){
        title = "budbee派送" 
        this.senderIndex = 0
      }
      this.tpData = { ...senders[this.senderIndex], basicServiceCode: 30, type, title }
      this.tpVisible = true
    },
    onSync() {
      this.$refs.tpForm.validate(valid => {
        if (!valid) return false;
        this.tping = true
        api.sync({
          ...this.tpData,
          additionalServiceCode: this.tpData.additionalServiceCode ? [this.tpData.additionalServiceCode] : [],
          orders: this.multipleSelection.map(v => v.uuid)
        })
        .then((res) => {
          this.tpVisible = false
          this.tping = false
          let successOrders = this.multipleSelection.map(v => v.uuid)
          if (res && res.orders && res.orders.length) {
            successOrders = successOrders.filter(v => res.orders.indexOf(v.uuid) === -1)
            this.$message.error(res.message || '同步失败')
          }
          else {
            this.$message.success('同步成功')
          }
          // 派送类型需要下载面单
          if (this.tpData.type == 2 && successOrders.length > 0) {
            this.handleBatchPrint(successOrders, 2)
          }
          else {
            this.fetchData()
          }
        })
        .catch(() => {
          this.tping = false
        })
      })
    },
    beforeBatchPrint() {
      this.pnData = {}
      this.pnVisible = true
    },
    batchPrint() {
      return this.handleBatchPrint(this.multipleSelection.map(v => v.uuid), this.pnData.type || '')
    },
    handleBatchPrint(ids, type = '') {
      this.printing = true
      return api.batchPrint(ids, type)
      .then(res => {
        this.printing = false
        //download(res, 'PN面单.zip')
        this.fetchData()
        window.location.href = `/rest/post/nord/file/export?file_name=${encodeURIComponent(res)}&type=1&token=${this.token}`
      })
      .catch(() => {
        this.printing = false
      })
    },
    handleBlur(row, key) {
      if (this.editMap.value && row[key] != this.editMap.value) {
        const body = { [key]: this.editMap.value }
        api.update(row.uuid, body)
        .then(() => {
          this.$message.success('更新成功')
          this.list = this.list.map(v => {
            if (v.uuid === row.uuid) {
              v = { ...v, ...body }
            }
            return v
          })
        })
      }
      this.editMap = {}
    },
    cellClick(row, column, cell, event) {
      event.preventDefault()
      event.stopPropagation()
      this.editMap = {
        uuid: row.uuid,
        value: row[column.property],
        key: column.property
      }
    },
    showReject(row) {
      this.$prompt('请填写拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '拒绝原因（选填）'
      })
      .then(({ value }) => {
        this.doReview(row, 3, value)
      })
    },
    preview(row) {
      this.previewImg = {
        visible: true,
        files: row.files.map(v => `/rest/order/file/${v.id}/download`)
      }
    },
    closeViewer() {
      this.previewImg = {}
    },
    handleFilePreview(file) {
      window.open(file.url)
    },
    handleFileChange(file, fileList) {
      if (file.status === 'success') {
        const id = file.response.data
        this.postData.files.push({
          name: file.name,
          size: file.size,
          url: `/rest/order/file/${id}/download`,
          id,
        })
      }
    },
    googleMapSearchAsync(query, callback) {
      if (!query) {
        callback([])
        return false
      }
      this.autocompleteService.getPlacePredictions({
        input: query,
        componentRestrictions: { country: ["se", 'chn'] },
        types: ['address']
      }, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          callback([])
          return false
        }
        callback(predictions.map(v => {
          return {
            value: v.structured_formatting?.main_text || v.description,
            description: v.description,
            place_id: v.place_id
          }
        }))
      })
    },
    handleAddressSelect(item) {
      this.placesService.getDetails({ placeId: item.place_id }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          let streatNumber = ''
          let route = ''
          let postcode = ''
          let city = ''
          let sublocality_level_1 = ''

          for (const component of place.address_components) {
            const componentType = component.types[0];

            switch (componentType) {
              case "street_number": {
                streatNumber = component.long_name
                break;
              }

              case "route": {
                route = component.short_name
                break;
              }

              case "postal_code": {
                postcode = `${component.long_name}${postcode}`;
                break;
              }

              case "postal_code_suffix": {
                postcode = `${postcode}-${component.long_name}`;
                break;
              }
                // 类似区一级
              case 'sublocality_level_1':
                //sublocality_level_1 = component.short_name
                break;
              case 'locality':
              case 'postal_town':
                city = component.short_name
                break
              case "locality":
                //document.querySelector("#locality").value = component.long_name;
                break;

              case "administrative_area_level_1": {
                //document.querySelector("#state").value = component.short_name;
                break;
              }
              case "country":
                //document.querySelector("#country").value = component.long_name;
                break;
            }
          }

          this.postData = {
            ...this.postData,
            to_address: `${sublocality_level_1 ? sublocality_level_1 + ' ' : ''}${route} ${streatNumber}`,
            postal_code: postcode.replace(/[^0-9]/ig, ''),
            g_place_id: place.place_id || '',
            city
          }

        }
      })
    },
    getPostnordByType(row, type = 1) {
      if (!row.pn_order || !row.pn_order.length) return '--'
      return row.pn_order.find(v => v.type === type)?.item_id || '--'
    },
    getExpressInfo(row) {
      let str = ''
      if(row.third_party == 'postnord') {
        str = 'PostNord'
      }else if(row.third_party == 'budbee') { 
        str = 'Budbee'
      }else if(row.third_party == 'sfexpress') { 
        str = '顺丰快递'
      }
      if(row.thirdPartyType == 1) {
        str = str + '--揽件--' + row.logistics_status_name
      }else if(row.thirdPartyType == 2) {
        str = str + '--派单--' + row.logistics_status_name
      }
      return str
    },

    
    // 重新推送PN，比如单据过期
    removePnOrder(row, type) {
      this.$confirm('您确定要移出该推送记录吗？请小心操作移出后操作不可逆，请重新操作『pn派送』。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        return api.removePnOrder(row.uuid, type)
        .then(() => {
          this.$message.success('移出成功')
          this.fetchData(this.listQuery.page)
        })
      })
    },
    // 揽件按钮 type= 1，3     1 pn揽件 2 pn派单 3 budbee派单 4 budbee揽件  5 顺丰派单 6 顺丰揽件 
    solicitation(type) {
      this.beforeSync(type)
    },
    // 派送按钮 type= 2，4
    delivery(type) {
      this.beforeSync(type)
    },

  }
}
</script>

<style lang="less" scoped>
.dipm-item {
  margin-bottom: 12px;
  .el-select {
    width: 300px;
  }
}
.dipm-fee {
  line-height: 1.5;
  margin-bottom: 22px;
  &-num {
    color: #ED6900;
    font-weight: 500;
  }
}
.pac-icon {
  width: 15px;
  height: 20px;
  margin-right: 6px;
  margin-top: 8px;
  display: inline-block;
  vertical-align: top;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
  background-position: -1px -161px;
  background-size: 34px;
}
</style>
